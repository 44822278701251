import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '../../utils/http';
import { CandidateVote, JobPostList, Score, VotableCandidates } from './skillsSurvey.types';

export const JOB_POST_LIST = createAsyncThunk<JobPostList[], { companyId: number }>(
  'skillsSurvey/jobPostList',
  async ({ companyId }): Promise<JobPostList[]> => {
    let data: JobPostList[];
    try {
      const response = await http(`jobs/v1/evaluations?companyId=${companyId}`, {
        method: 'GET',
      });
      data = response.data.data.evaluations;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);

export const RETRIEVE_VOTABLE_CANDIDATES = createAsyncThunk<
  VotableCandidates[],
  { jobPostId: number; companyId: number }
>(
  'skillsSurvey/retrieveVotableCandidates',
  async ({ companyId, jobPostId }): Promise<VotableCandidates[]> => {
    let data: VotableCandidates[];
    try {
      const response = await http(
        `jobs/v1/evaluations/${jobPostId}/candidates?companyId=${companyId}`,
        {
          method: 'GET',
        }
      );
      data = response.data.data.candidates;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);

export const RETRIEVE_CANDIDATE = createAsyncThunk<
  CandidateVote,
  { jobPostId: number; companyId: number; candidateId: number }
>(
  'skillsSurvey/retrieveCandidateScore',
  async ({ jobPostId, companyId, candidateId }): Promise<CandidateVote> => {
    let data: CandidateVote;
    try {
      const response = await http(
        `jobs/v1/evaluations/${jobPostId}/candidates/${candidateId}/responses?companyId=${companyId}`,
        {
          method: 'GET',
        }
      );
      data = response.data.data.candidate;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }

    return data;
  }
);

export const SAVE_CANDIDATE_SCORE = createAsyncThunk<
  CandidateVote,
  {
    jobPostId: number;
    candidateId: number;
    votations: Score[];
    questionId: number;
    companyId: number;
  }
>(
  'skillsSurvey/saveCandidateVote',
  async ({ jobPostId, candidateId, votations, questionId, companyId }): Promise<CandidateVote> => {
    let data: CandidateVote;
    try {
      const response = await http(
        `jobs/v1/evaluations/${jobPostId}/candidates/${candidateId}/${questionId}?companyId=${companyId}`,
        {
          method: 'PATCH',
          data: { responses: [...votations] },
        }
      );
      data = response.data.data.candidate;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);
