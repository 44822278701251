import { Middleware } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { Dispatch } from 'react';
import { setError, setSuccess } from '../store/notification/reducer';
import {
  JOB_POST_LIST,
  RETRIEVE_VOTABLE_CANDIDATES,
  SAVE_CANDIDATE_SCORE,
} from '../store/skillsSurvey/thunk';

export const notificationMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState();
  const companyId = state?.auth?.selectedCompany?.id;
  const jobPostId = state?.skillsSurvey?.selectedJobPostId;

  if (action.type === SAVE_CANDIDATE_SCORE.fulfilled.toString()) {
    const { dispatch }: { dispatch: Dispatch<any> } = store;
    dispatch(setSuccess(i18next.t('skillSurvey:mainArea.saveSuccessMessage')));
    dispatch(RETRIEVE_VOTABLE_CANDIDATES({ companyId, jobPostId }));
  }

  if (action.type === SAVE_CANDIDATE_SCORE.rejected.toString()) {
    store.dispatch(setError(i18next.t('skillSurvey:mainArea.saveRejectedMessage')));
  }
  if (action.type === JOB_POST_LIST.rejected.toString()) {
    store.dispatch(setError(i18next.t('skillSurvey:jobPost.rejectedMessage')));
  }
  return result;
};
