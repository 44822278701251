import { useHistory } from 'react-router-dom';
import { Routes } from '../constants/routes';
import { CompanyDbModel } from '../models/company.model';
import { JwtUserPayload } from '../models/userDb.model';
import { changeCompany, resetUser } from '../store/auth/actions';
import {
  getAuthLoading,
  getAuthUser,
  getIsOtpLimitReached,
  getIsOtpWrong,
  getLogged,
  getLoginEmail,
  getOtpCodeLoading,
  getOtpRequired,
  getPasswordChangeRequired,
  getSelectedCompany,
  getUserAvatar,
  getUserCompanies,
  getUserEmail,
  getUserName,
  getUserPermissions,
  getUserSurname,
} from '../store/auth/selectors';
import { CHANGE_TEMPORARY_PASSWORD, LOGIN, LOGIN_OTP_VERIFY, LOGOUT } from '../store/auth/thunk';
import { useAppDispatch, useAppSelector } from '../store/store';

interface Properties {
  user: JwtUserPayload;
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  avatar?: string | null;
  companies: CompanyDbModel[];
  selectedCompany: CompanyDbModel;
  passwordChangeRequired?: boolean;
  isLogged: boolean;
  loading: boolean;
  permissions: string[] | undefined;
  otpRequired: boolean;
  isOtpWrong: boolean;
  isOtpLimitReached: boolean;
  otpCodeLoading: boolean;
  loginEmail: string | null;
  loginHandler: (email: string, password: string, token: string) => void;
  logoutHandler: () => void;
  companyChangeHandler: (companyId: number) => void;
  changeTemporaryPasswordHandler: (password: string) => void;
  otpVerifyHandler: (code: string) => void;
  resetUserHandler: () => void;
}

const useAuth = (): Properties => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const loading = useAppSelector(getAuthLoading);
  const user = useAppSelector(getAuthUser);
  const permissions = useAppSelector(getUserPermissions);
  const name = useAppSelector(getUserName);
  const surname = useAppSelector(getUserSurname);
  const email = useAppSelector(getUserEmail);
  const avatar = useAppSelector(getUserAvatar);
  const companies = useAppSelector(getUserCompanies);
  const selectedCompany = useAppSelector(getSelectedCompany);
  const passwordChangeRequired = useAppSelector(getPasswordChangeRequired);
  const isLogged = useAppSelector(getLogged);
  const otpRequired = useAppSelector(getOtpRequired);
  const isOtpWrong = useAppSelector(getIsOtpWrong);
  const isOtpLimitReached = useAppSelector(getIsOtpLimitReached);
  const otpCodeLoading = useAppSelector(getOtpCodeLoading);
  const loginEmail = useAppSelector(getLoginEmail);

  const loginHandler = (email: string, password: string, token: string) =>
    dispatch(LOGIN({ email, password, token }));

  const otpVerifyHandler = (otp_code: string) => {
    if (loginEmail) {
      dispatch(LOGIN_OTP_VERIFY({ otp_code, email: loginEmail }));
    }
  };

  const logoutHandler = (): void => {
    dispatch(LOGOUT());
  };

  const companyChangeHandler = (companyId: number) => {
    const route = user.capabilities.role === 'evaluator' ? Routes.SKILLS_SURVEY : Routes.CANDIDATES;
    history.push(`/${companyId}/${route}`);
    dispatch(changeCompany(companyId));
  };

  const changeTemporaryPasswordHandler = (password: string) =>
    dispatch(CHANGE_TEMPORARY_PASSWORD(password));

  const resetUserHandler = () => dispatch(resetUser());

  return {
    user,
    name,
    surname,
    email,
    avatar,
    companies,
    selectedCompany,
    passwordChangeRequired,
    isLogged,
    loading,
    permissions,
    otpRequired,
    isOtpWrong,
    isOtpLimitReached,
    otpCodeLoading,
    loginEmail,
    loginHandler,
    logoutHandler,
    companyChangeHandler,
    changeTemporaryPasswordHandler,
    otpVerifyHandler,
    resetUserHandler,
  };
};

export default useAuth;
