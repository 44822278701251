import { createSlice } from '@reduxjs/toolkit';

import { NotificationState } from './notification.types';

const NotificationInitialState: NotificationState = {
  error: null,
  success: null,
};

const NOTIFICATION_SLICE = 'Notification';

const slice = createSlice({
  name: NOTIFICATION_SLICE,
  initialState: NotificationInitialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});
export const { setError, setSuccess } = slice.actions;

export default slice.reducer;
