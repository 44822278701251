import { useEffect } from 'react';
import styled from 'styled-components';
import { setError, setSuccess } from '../../../../store/notification/reducer';
import { getMessages } from '../../../../store/notification/selectors';
import { useAppDispatch, useAppSelector } from '../../../../store/store';

const Notification = () => {
  // Hooks
  const dispatch = useAppDispatch();
  // Selectors
  const { error, success } = useAppSelector(getMessages);
  // Effects
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setError(null));
      dispatch(setSuccess(null));
    }, 3000) as ReturnType<typeof setTimeout>;

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, success, error]);

  if (success) {
    return (
      <SuccessContainer>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 12.5L11 15.5L16 9.5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            stroke="white"
            strokeWidth="2"
          />
        </svg>
        <Text>{success}</Text>
      </SuccessContainer>
    );
  }

  if (error) {
    return (
      <ErrorContainer>
        <IconContainer>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M12 7V13M12 16.5V17"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </IconContainer>
        <Text>{error}</Text>
      </ErrorContainer>
    );
  }
};

export default Notification;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SuccessContainer = styled.div`
  pointer-events: none;
  position: fixed;
  top: 25px;
  right: 25px;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacer[14]};
  z-index: 200;

  background-color: ${({ theme }) => theme.lightUI.layer.success};
  border: ${({ theme }) => theme.lightUI.layer.success};
  color: ${({ theme }) => theme.lightUI.text.emphasis};
  border-radius: ${({ theme }) => theme.radius.default};
`;

const ErrorContainer = styled.div`
  pointer-events: none;
  position: fixed;
  top: 25px;
  right: 25px;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacer[14]};
  z-index: 200;

  background-color: ${({ theme }) => theme.lightUI.layer.danger};
  border: ${({ theme }) => theme.lightUI.layer.danger};
  color: ${({ theme }) => theme.lightUI.text.emphasis};
  border-radius: ${({ theme }) => theme.radius.default};
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 1rem;
  margin: 0 0 0 10px;
`;
