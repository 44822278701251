import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { baseApi, gatewayApi } from '../api/api';
import { ApiErrorsMiddleware } from '../api/apiErrorsMiddleware';
import applications from '../features/applications/reducer';
import customizations from '../features/companyCustomizations/store/reducer';
import newCustomQuestionnaire from '../features/customQuestionnaires/store/reducer';
import { notificationMiddleware } from '../middleware/notificationMiddleware';
import auth from './auth/reducer';
import editRecruitment from './editRecruitment/reducer';
import imports from './imports/reducer';
import jobAreas from './jobAreas/reducer';
import jobPosts from './jobs/reducer';
import languages from './languages/reducer';
import newRecruitment from './newRecruitment/reducer';
import notification from './notification/reducer';
import popup from './popup/reducer';
import skills from './skills/reducer';
import skillsSurvey from './skillsSurvey/reducer';
import team from './team/reducer';
import teamFeedbacks from './teamFeedbacks/reducer';
import ui from './ui/reducer';

const { VITE_ENVIRONMENT } = import.meta.env;

const rootReducer = combineReducers({
  popup,
  ui,
  auth,
  jobPosts,
  jobAreas,
  newRecruitment,
  editRecruitment,
  newCustomQuestionnaire,
  skills,
  languages,
  team,
  teamFeedbacks,
  imports,
  applications,
  customizations,
  skillsSurvey,
  notification,
  [baseApi.reducerPath]: baseApi.reducer,
  [gatewayApi.reducerPath]: gatewayApi.reducer,
});

export const Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })
      // RTK Query API middleware
      .concat(
        baseApi.middleware,
        gatewayApi.middleware,
        ApiErrorsMiddleware,
        notificationMiddleware
      ),
  devTools: VITE_ENVIRONMENT !== 'production', // Enable Redux DevTools only in development envs
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
type AppDispatch = typeof Store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
