import isPropValid from '@emotion/is-prop-valid';
import { Suspense, useEffect, useLayoutEffect } from 'react';
import styled, { StyleSheetManager } from 'styled-components';
import './App.css';
import Loader from './components/Loader/Loader';
import PasswordChangeRequired from './components/Modal/Contents/PasswordChangeRequired/PasswordChangeRequired';
import Modal from './components/Modal/Modal';
import BlockingOverlay from './components/Modal/Overlay/BlockingOverlay';
import Overlay from './components/Modal/Overlay/Overlay';
import Router from './components/Routing/Router/Router';
import Notification from './components/SoftSkillsSurvey/components/ui/Notification';
import PopupBackdrop from './components/ui/PopupBackdrop/PopupBackdrop';
import PopupMessage from './components/ui/PopupMessage/PopupMessage';
import { BACKEND_ENDPOINTS, BASE_URL } from './constants/endpoints';
import useAuth from './hooks/useAuth';
import useModals from './hooks/useModals';
import usePopupMessage from './hooks/usePopupMessage';
import { STORE_USER } from './store/auth/thunk';
import { useAppDispatch, useAppSelector } from './store/store';
import { closePopupBackdrop } from './store/ui/actions';
import { getPopupBackdrop } from './store/ui/selectors';
import { setAuthorizationToken } from './utils/http';
import { getToken } from './utils/localStorage';

const { VITE_BACKOFFICE_BASE_URL, VITE_HEALTH_CHECK_INTERVAL } = import.meta.env;

const MAINTENANCE_URL = `${VITE_BACKOFFICE_BASE_URL}/maintenance.html`;

const App = () => {
  const dispatch = useAppDispatch();

  const {
    loading,
    isLogged,
    companies,
    permissions,
    user,
    passwordChangeRequired,
    changeTemporaryPasswordHandler,
  } = useAuth();

  const { overlay, closeAllModalsHandler } = useModals();

  const { success: successMessage, error: errorMessage } = usePopupMessage();

  const backdrop = useAppSelector(getPopupBackdrop);
  const backdropClickHandler = () => dispatch(closePopupBackdrop());

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    fetch(`${BASE_URL}/${BACKEND_ENDPOINTS.STATUS}`)
      .then((res) => {
        if (res.status !== 200) {
          window.location.replace(MAINTENANCE_URL);
        } else {
          interval = setInterval(
            () => {
              fetch(`${BASE_URL}/${BACKEND_ENDPOINTS.STATUS}`)
                .then((res) => {
                  if (res.status !== 200) {
                    clearInterval(interval);
                    window.location.replace(MAINTENANCE_URL);
                  }
                })
                .catch((_) => {
                  clearInterval(interval);
                  window.location.replace(MAINTENANCE_URL);
                });
            },
            VITE_HEALTH_CHECK_INTERVAL ? +VITE_HEALTH_CHECK_INTERVAL : 3000
          );
        }
      })
      .catch((_) => {
        clearInterval(interval);
        window.location.replace(MAINTENANCE_URL);
      });
    return () => {
      clearInterval(interval);
    };
  }, []);

  useLayoutEffect(() => {
    const accessToken: string | null = getToken();
    if (accessToken) {
      const sessionNeedRestore: boolean = Object.keys(user).length === 0;
      if (sessionNeedRestore) {
        setAuthorizationToken(accessToken);
        dispatch(STORE_USER(accessToken));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Router
        loading={loading}
        isLogged={isLogged}
        companies={companies}
        permissions={permissions}
      />
      <Notification />
      <PopupMessage
        msg={successMessage ? successMessage : errorMessage ? errorMessage : null}
        type={successMessage ? 'success' : errorMessage ? 'error' : 'info'}
        show={!!(successMessage || errorMessage)}
      />
      <Overlay
        visible={overlay}
        onClick={closeAllModalsHandler}
      />
      <BlockingOverlay visible={passwordChangeRequired ?? false} />
      <Modal
        maxWidth={350}
        opened={passwordChangeRequired ?? false}
        backgroundColor="white"
      >
        <PasswordChangeRequired
          loading={loading}
          onSubmit={changeTemporaryPasswordHandler}
        />
      </Modal>
      <PopupBackdrop
        opened={backdrop}
        onClick={backdropClickHandler}
      />
    </>
  );
};

const WrappedApp = () => {
  // NOTE: https://styled-components.com/docs/faqs#what-do-i-need-to-do-to-migrate-to-v6

  // REFACTOR: StyleSheetManager and shouldForwardProp make old (non transient) props still valid;
  // to refactor is needed to update all non transient props => (iconOnly: boolean to $iconOnly: boolean)

  // This implements the default behavior from styled-components v5
  function shouldForwardProp(propName: string, target: unknown) {
    if (typeof target === 'string') {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Suspense
        fallback={
          <Container>
            <Loader
              size={128}
              position="static"
            />
          </Container>
        }
      >
        <App />
      </Suspense>
    </StyleSheetManager>
  );
};

export default WrappedApp;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
