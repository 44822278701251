import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CandidateVote,
  JobPostList,
  SkillsSurveyState,
  VotableCandidates,
} from './skillsSurvey.types';
import {
  JOB_POST_LIST,
  RETRIEVE_CANDIDATE,
  RETRIEVE_VOTABLE_CANDIDATES,
  SAVE_CANDIDATE_SCORE,
} from './thunk';

const SkillsSurveyInitialState: SkillsSurveyState = {
  rejectedList: false,
  rejectedCandidate: false,
  loadingList: false,
  loadingCandidate: false,
  loadingSave: false,
  loadingJobPostList: false,
  votableCandidates: [],
  candidateVote: null,
  jobPostList: [],
  selectedCandidate: null,
  selectedJobPostId: null,
};

const SKILLS_SURVEY_SLICE = 'SkillsSurvey';

const slice = createSlice({
  name: SKILLS_SURVEY_SLICE,
  initialState: SkillsSurveyInitialState,
  reducers: {
    resetVotableCandidates: (state) => {
      state.votableCandidates = [];
      state.selectedCandidate = null;
    },
    resetCandidateVote: (state) => {
      state.candidateVote = null;
    },
    setSelectedJobPostId: (state, action) => {
      state.selectedJobPostId = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(JOB_POST_LIST.pending, (state) => ({
        ...state,
        loadingJobPostList: true,
      }))
      .addCase(JOB_POST_LIST.rejected, (state) => ({
        ...state,
        loadingJobPostList: false,
      }))
      .addCase(JOB_POST_LIST.fulfilled, (state, action: PayloadAction<JobPostList[]>) => ({
        ...state,
        loadingJobPostList: false,
        jobPostList: [...action.payload],
      }))
      .addCase(RETRIEVE_VOTABLE_CANDIDATES.pending, (state) => ({
        ...state,
        loadingList: true,
        rejectedList: false,
      }))
      .addCase(RETRIEVE_VOTABLE_CANDIDATES.rejected, (state) => ({
        ...state,
        loadingList: false,
        rejectedList: true,
      }))
      .addCase(
        RETRIEVE_VOTABLE_CANDIDATES.fulfilled,
        (state, action: PayloadAction<VotableCandidates[]>) => ({
          ...state,
          loadingList: false,
          rejectedList: false,
          votableCandidates: [...action.payload],
        })
      )
      .addCase(RETRIEVE_CANDIDATE.pending, (state) => ({
        ...state,
        loadingCandidate: true,
        rejectedCandidate: false,
      }))
      .addCase(RETRIEVE_CANDIDATE.rejected, (state) => ({
        ...state,
        loadingCandidate: false,
        rejectedCandidate: true,
      }))
      .addCase(RETRIEVE_CANDIDATE.fulfilled, (state, action: PayloadAction<CandidateVote>) => ({
        ...state,
        loadingCandidate: false,
        rejectedCandidate: false,
        candidateVote: { ...action.payload, alias: action.payload.alias || '' },
      }))
      .addCase(SAVE_CANDIDATE_SCORE.pending, (state) => {
        return {
          ...state,
          loadingSave: true,
        };
      })
      .addCase(SAVE_CANDIDATE_SCORE.rejected, (state) => {
        return {
          ...state,
          loadingSave: false,
        };
      })
      .addCase(SAVE_CANDIDATE_SCORE.fulfilled, (state, action) => {
        return {
          ...state,
          loadingSave: false,
          candidateVote: action.payload,
          selectedCandidate: action.payload.id,
        };
      }),
});
export const { resetVotableCandidates, resetCandidateVote, setSelectedJobPostId } = slice.actions;

export default slice.reducer;
