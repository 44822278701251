import { RootState } from '../store';

import { createSelector } from '@reduxjs/toolkit';
import { NotificationState } from './notification.types';

const NotificationStore = (state: RootState) => state.notification;

export const getMessages = createSelector(
  NotificationStore,
  (NotificationState: NotificationState) => {
    return {
      error: NotificationState.error,
      success: NotificationState.success,
    };
  }
);
