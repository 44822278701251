import { FunctionComponent, lazy } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Routes, canNavigate } from '../../../constants/routes';
import useAuth from '../../../hooks/useAuth';
import { CompanyDbModel } from '../../../models/company.model';
import { getWasSaml } from '../../../store/auth/selectors';
import { useAppSelector } from '../../../store/store';
import Loader from '../../Loader/Loader';
const PrivateRouter = lazy(() => import('./PrivateRouter'));
const PublicRouter = lazy(() => import('./PublicRouter'));

interface UnAuthenticatedRouteProps extends RouteProps {
  component: FunctionComponent<RouteComponentProps>;
  isLogged: boolean;
  loading: boolean;
  companies: CompanyDbModel[];
}

interface AuthenticatedRouteProps extends RouteProps {
  component: FunctionComponent<RouteComponentProps>;
  isLogged: boolean;
  loading: boolean;
  permissions?: string[];
}

const UnAuthenticatedRoute = ({ component: Component, ...rest }: UnAuthenticatedRouteProps) => {
  const { loading, companies, isLogged } = { ...rest };
  const { user } = useAuth();

  if (loading) {
    return (
      <Container>
        <Loader
          size={128}
          position="relative"
        />
      </Container>
    );
  }

  let company_id: number;

  if (companies.length > 0) {
    company_id = companies[0].id;
  }

  const getRedirectPath = () => {
    switch (user.capabilities.role) {
      case 'evaluator':
        return `/${company_id}/${Routes.SKILLS_SURVEY}`;
      default:
        return `/${company_id}/${Routes.CANDIDATES}`;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? <Redirect to={getRedirectPath()} /> : <Component {...props} />
      }
    />
  );
};

const AuthenticatedRoute = ({ component: Component, ...rest }: AuthenticatedRouteProps) => {
  const { path, isLogged, permissions } = { ...rest };

  const wasSaml = useAppSelector(getWasSaml);

  if (permissions && !canNavigate(path as string, permissions)) {
    return <Redirect to={Routes.UNAUTHORIZED} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? (
          <Component
            {...props}
            {...rest}
          />
        ) : (
          <Redirect
            to={
              wasSaml
                ? `${Routes.PUBLIC}/${Routes.LOGIN}?saml=true`
                : `${Routes.PUBLIC}/${Routes.LOGIN}`
            }
          />
        )
      }
    />
  );
};

const Router = ({
  isLogged,
  companies,
  loading,
  permissions,
}: {
  isLogged: boolean;
  companies: CompanyDbModel[];
  loading: boolean;
  permissions?: string[];
}) => {
  return (
    <Switch>
      {/* Public routes */}
      <UnAuthenticatedRoute
        isLogged={isLogged}
        companies={companies}
        loading={loading}
        path={Routes.PUBLIC}
        component={PublicRouter}
      />
      {/* Protected routes */}
      <AuthenticatedRoute
        isLogged={isLogged}
        permissions={permissions}
        loading={loading}
        path={Routes.BASE}
        component={PrivateRouter}
      />
      {/* Handle unmatched routes */}
      <Route path="*">
        <Redirect to={`${Routes.PUBLIC}/${Routes.LOGIN}`} />
      </Route>
    </Switch>
  );
};

export default Router;
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
