import { HTMLAttributes, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  size?: 'sm' | 'lg';
  appearance?: 'primary' | 'secondary' | 'text';
  animated?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  dropdownContainer?: boolean;
  type?: 'button' | 'submit' | 'reset';
  iconOnly?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
}

const BlackButton = ({
  size = 'lg',
  appearance = 'primary',
  animated = false,
  disabled = false,
  rounded = false,
  dropdownContainer,
  type,
  iconOnly = false,
  onClick,
  children,
  ...otherProps
}: Props) => {
  if (rounded) {
    return (
      <RoundContainer
        type={type ? type : 'button'}
        iconOnly={iconOnly}
        size={size}
        appearance={appearance}
        disabled={disabled}
        dropdownContainer={dropdownContainer}
        onClick={onClick ? onClick : () => null}
        {...otherProps}
      >
        {children}
      </RoundContainer>
    );
  }

  return (
    <Container
      type={type ? type : 'button'}
      iconOnly={iconOnly}
      size={size}
      appearance={appearance}
      animated={animated}
      disabled={disabled}
      dropdownContainer={dropdownContainer}
      onClick={onClick ? onClick : () => null}
      {...otherProps}
    >
      {children}
    </Container>
  );
};

export default BlackButton;

const Container = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${({ appearance }) => (appearance === 'primary' ? '#111111' : '#FFFFFF')};
  color: ${({ appearance }) => (appearance === 'primary' ? '#FFFFFF' : '#111111')};
  border-radius: ${({ theme }) => theme.radius.default};
  outline: none;
  border: ${({ appearance }) => (appearance === 'text' ? 'none' : '1px solid #111111')};
  font-size: ${({ size }) => (size === 'sm' ? '14px' : '16px')};
  font-weight: 600;
  padding: ${({ size }) => (size === 'sm' ? '4px 12px' : '8px 16px')};
  margin-right: 10px;
  ${({ dropdownContainer }) => (dropdownContainer ? 'position: relative;' : null)};
  transition: all 0.2s linear;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  > svg {
    ${({ iconOnly }) => (iconOnly ? null : 'margin-right: 10px;')};
  }

  ${({ animated, appearance }) =>
    animated
      ? appearance === 'primary'
        ? `&:hover {
          background-color: #FFFFFF;
          color: #111111;
        }`
        : `&:hover {
          background-color: #111111;
          color: #FFFFFF;
        }`
      : null}
`;

const RoundContainer = styled(Container)`
  border-radius: ${({ theme }) => theme.radius.default};
`;
